import { Disclosure } from '@headlessui/react';
import { MinusIcon, PlusIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';

const faqs = [
  {
    question: 'faqs_question_1',
    answer: 'faqs_answer_1',
  },
  {
    question: 'faqs_question_2',
    answer: 'faqs_answer_2',
  },
  {
    question: 'faqs_question_3',
    answer: 'faqs_answer_3',
  },
  {
    question: 'faqs_question_4',
    answer: 'faqs_answer_4',
  },
  {
    question: 'Can I integrate the Medical Chat models into my platform?',
    answer:
      'You can incorporate the Medical Chat models into your platform by creating a chatbot on the Chat Data platform (http://chat-data.com) and choosing either the medical-chat-human or medical-chat-vet models.',
  },
  {
    question: 'How can I cancel the Premium plan?',
    answer:
      'To cancel your subscription with Medical Chat, you have the option to downgrade your account at any time through the customer portal provided by Stripe, the platform through which you initially subscribed to the Premium plan. Alternatively, you can directly contact our support team via email at admin@chat-data.com, and they will assist you promptly, typically responding within 12 hours.',
  },
  {
    question: 'Does Medical Chat offer app in IOS or Android?',
    answer: `Currently, we do not have an iOS or Android app available in the app store. However, you can follow these two instructions (iOS: https://www.guidingtech.com/guide-to-using-web-apps-on-iphone-and-ipad and Android: https://beebom.com/ways-turn-any-website-android-app) to save our website as a web app on your phone screen and use it similarly to an app on your phone.`,
  },
  {
    question: "Why can't I sign up?",
    answer:
      'The issue may be that another email has been used to sign up with the same device in the past. To prevent abuse of free credits accounts, we only allow premium plan accounts to log in with the same device. A free account can be logged in with a single email per device.',
  },
  {
    question: 'Is there a free plan in Medical Chat?',
    answer: `Yes, all registered accounts will automatically receive 10 free credits at the beginning of each month. This allows free plan users to ask questions without any hassle. It's important to note that these free message credits follow a "use it or lose it" mechanism.`,
  },
  {
    question: "Can I upload a doctor's prescription?",
    answer: `Yes, you can utilize the PDF uploading feature to upload a doctor's prescription and ask related questions about it. It's important to note that we do not save your data on our server.`,
  },
];

export default function FAQs() {
  const { t } = useTranslation();
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl px-6 sm:py-8 lg:px-8 lg:py-10">
        <div className="mx-auto max-w-7xl divide-y divide-gray-900/10">
          <h2 className="text-center text-2xl font-bold leading-10 tracking-tight text-gray-900">
            {t('Frequently asked questions')}
          </h2>
          <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
            {faqs.map((faq) => (
              <Disclosure as="div" key={faq.question} className="pt-6">
                {({ open }) => (
                  <>
                    <dt>
                      <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                        <span className="text-base font-semibold leading-7">
                          {t(faq.question)}
                        </span>
                        <span className="ml-6 flex h-7 items-center">
                          {open ? (
                            <MinusIcon className="h-6 w-6" aria-hidden="true" />
                          ) : (
                            <PlusIcon className="h-6 w-6" aria-hidden="true" />
                          )}
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base leading-7 text-gray-600">
                        {t(faq.answer)}
                      </p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
